// src/components/Layout.js
import React from 'react';

import SidebarLayout from './SidebarLayout';
import Footer from './Footer';
import  "primereact/resources/themes/arya-green/theme.css";

const Layout = ({ children }) => {
    return (
        <div className="content">
            <div className=" h-screen">
                <SidebarLayout />
                <div className=" w-full contents">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
