// src/components/Footer.js
import React from 'react';


const Footer = () => {
    return (
        <div className='w-full bg-green-500  py-4 justify-content-center flex fixed bottom-0 mt-6'>
            <p className='text-white'>© 2024 Jigawa Paliative Shops  Management System </p>
        </div>
    );
};

export default Footer;
