// src/pages/Dashboard.js
import React from 'react';
import { FaUsers } from '@react-icons/all-files/fa/FaUsers';
import { Card } from 'primereact/card';



function BsBoxArrowUpRight(props) {
  return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 16 16" height="1em" width="1em" {...props}><path fillRule="evenodd" d="M1.5 13A1.5 1.5 0 003 14.5h8a1.5 1.5 0 001.5-1.5V9a.5.5 0 00-1 0v4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h4a.5.5 0 000-1H3A1.5 1.5 0 001.5 5v8zm7-11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.5H9a.5.5 0 01-.5-.5z" clipRule="evenodd" /><path fillRule="evenodd" d="M14.354 1.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z" clipRule="evenodd" /></svg>;
}

const items = [
    {
        'title':"Total Users",
        'count':5000
    },
    {
        'title':"Total Sales",
        'count':5000
    },
    {
        'title':"Total Revenue",
        'count':5000
    },
    {
        'title':"Total Users",
        'count':5000
    }
]





const Dashboard = () => {
    return (
    <div className="container-fluid px-2 py-5">
        <div className='grid sm:grid-cols-1 md:grid-cols-4 gap-6 mx-auto justify-center items-center'>
            {items.map((item)=>{
                    return(
                        <>
                        <div className=' bg-white p-4 rounded-md  border w-1/5 h-auto grid grid-cols-3 '>
                            <div className='bg-green-500  text-white p-3  rounded-lg justify-items-center'><i className="pi pi-user items-center" style={{ fontSize: '2.0rem' }}></i></div>
                            <div className='px-2 py-2  '>
                                <span className='flex flex-row font-extrabold font-sans text-1xl'>{item.title}</span>
                                <span className='flex flex-row text-gray-500'>{item.count}</span>
                            </div>
                        </div>
                        </>
                    )
                })
            }
        </div>


    </div>
    );
};

export default Dashboard;
